@import '../../Utils/index'


.Mobilenav
    +height(170px)
    +index(20)
    .parent
        .navcover
            +bg($gray-900, white)
            +width(100%)
            +fixed
            +index(20)
            +p(0 1rem)
            .top
                +height(60px)
                +flex(1rem, space-between, center)
                .logo
                    +font(22px, null, 700)
                    +pin(0)
                    +flex(null, flex-start, center)
                    .name

                        .haz
                            +color(red)
                        .cui
                            +color(orange)
                            +italic

                .icons
                    +flex(1rem, space-between, center)
            .input
                #input
                    +radius(.2rem)
                    +height(50px, white)
                    +p(.3rem )
                    +flex(.5rem)
                    input
                        +container(100%, 100%, transparent)
                        +pin(1rem)
                        +nooutline
                    button
                        +nooutline
                        +pin(1rem)
                        +radius(.2rem)
                        +pointer
                        +size(13px)
                        +bg(orange, white)
            .menu
                +height(50px)
                +flex(1rem, space-between, center)
                +size(13px)
        .menus
            +container(300px, 100%, $gray-900)
            +fixed
            +color(white)
            +p(1rem)
            +index(20)
            +top(160px)
            +autoY
            +columnFlex(1rem)
            +transition5
            +translateX(200vw)
            ul
                +noList
                +columnFlex(1rem)
                a
                    +noDecoration
                    +font(14px, white)
