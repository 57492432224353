@import '../../Utils/index'


.Compb
    +pbl(3rem 1rem)
    +pin(4rem)
    @media (max-width: 1100px) 
        +pin(0)
    .parent
        +grid(1rem)
        +p(0 1rem)
        +shadow(2px 2px 5px whitesmoke)
        +gtc(repeat(auto-fit, minmax(200px, 1fr)))
        .card
            +flex(1rem)
            +p(1rem)
            +pointer
            .left
                +container(50px, 50px, null)
                +relative
                +hidden
                .icona
                    +absolute
                    +top(.2rem)
                    +font(25px, black)
                    +left(50%)
                    +transition5
                    +translateX(-50%)
                @media (max-width: 1000px) 
                    +columnFlex(1rem, center, center)
            
                .iconb
                    +absolute
                    +top(-5rem)
                    +font(25px, red)
                    +transition5
                    +left(50%)
                    +translateX(-50%)

            .right
                +columnFlex(.3rem)
                +br(2px solid $gray-300)
                +pr(2rem)
                +size(13px)
                @media (max-width: 1000px) 
                    +nob()
                    +pr(1rem)
                    +centerText()
                .head
                    +transition5
                    +font(1px, $gray-dark, 800)
                    +size(11px)
                    &~p
                        +size(13px)
            @media (max-width: 1000px) 
                +columnFlex(0, center, center)
                    
            &:hover > .left > .icona
                    +top(5rem)
            &:hover > .left > .iconb
                    +top(.2rem)
            &:hover > .right > .head
                +color(red)
                
        @media (max-width: 1000px) 
            +grid(0px)
          

            