@import '../../Utils/index'

.Footer
    .parent
        +flex(2rem)
        +pbl(6rem)
        +pin(7rem)
        +size(13px)
        +wrap
        +bg($gray-900)
        @media (max-width: 1000px) 
            +pin(2rem)
        .header
            +font(15px, white, 800)
        ul
            +columnFlex(1rem)
            +noList
            a
                +noDecoration
                +color(white)
        .one
            flex: 1
            +minWidth(200px)
            +columnFlex(1.5rem)
        .two
            flex: 1
            +minWidth(200px)
            +columnFlex(1.5rem)
        .three
            +columnFlex(1.5rem)
            +minWidth(270px)
            flex: 3
            +color(white)
            .input-btn
                +flex(.5rem)
                input
                    +container(40px, 100%, $gray-500)
                    +nooutline
                    +radius(.2rem)
                    +pin(1rem)
                button
                    +bg($hotpink, white)
                    +radius(.2rem)
                    +nooutline
                    +pointer
                    +pin(1rem)
        .four
            flex: 1
            +minWidth(200px)
            +columnFlex(1.5rem)
            .socials
                +flex(1rem, null, center)
                &>*
                    +color(white)
                    +transition5
                    +pointer
                    &:hover
                        +color(red)