=b($border)
    border: $border
=bt($border)
    border-top: $border
=bl($border)
    border-left: $border
=bb($border)
    border-bottom: $border
=br($border)
    border-right: $border
=bin($border)
    border-inline: $border
=bbl($border)
    border-block: $border

=nob()
    border: none
=nobt()
    border-top: none
=nobl()
    border-left: none
=nobb()
    border-bottom: none
=nobr()
    border-right: none
=nobin()
    border-inline: none
=nobbl()
    border-block: none
=nooutline()
    outline: none
    border: none

=radius($radius)
    border-radius: $radius
=topRadius($radius)
    border-radius: $radius $radius 0 0
=leftRadius($radius)
    border-radius: $radius 0 0 $radius
=rightRadius($radius)
    border-radius: 0 $radius $radius 0
=bottomRadius($radius)
    border-radius: 0 0 $radius $radius
=noRadius()
    border-radius: 0
