@import '../../Utils/index'

.Compa
    +pin(3rem)
    @media (max-width: 1100px) 
        +pin(0)
    h2
        +style(italic)
        +centerText
        +pbl(3rem 1rem)
        +color(red)
    .parent
        +grid($columnGap: 1rem, $rowGap: 2rem)
        +p(2rem)
        +gtc(repeat(auto-fit, minmax(230px, 1fr)))
        .card
            +columnFlex(0rem)
            +hidden
            +pointer
            +radius(.3rem)
            +relative
            +shadow(2px 2px 10px 2px lightgrey)
            a
                +absolute
                +top(0)
                +left(0)
                +index(2)
                +fullPercentage
            .top
                +columnFlex(.5rem)
                .like
                    +flex(null, space-between, center)
                    +p(.8rem 1rem)
                    .stars
                        +flex(1rem, null, center)
                        &>*
                            +pointer
                            +color(orange)
                    .heart
                        +opacity(0)
                        +transition5
                        +noPointer
                        +index(3)
                        &:hover
                            +color(red)
                .photo
                    +height(200px, white)
                    +centerFlex
                    +relative
                    .img1
                        +fullPercentage(white)
                        +absolute
                        img
                            +containImage
                    .img2
                        +fullPercentage(white)
                        +absolute
                        +opacity(0)
                        +transition5
                        img
                            +containImage
                    .links
                        +container(50px, 100%, transparent)
                        +centerFlex(.2rem)
                        +index(3)
                        .icona
                            +translateX(-150px)
                        
                        .iconc
                            +translateX(150px)
                        .icon
                            +size(20px)
                            +bg($gray-200)
                            +pointer
                            +p(.5rem 1rem)
                            +contentBox
                            +noPointer
                            +opacity(0)
                            +transition5
                            +radius(.2rem)
                            &:hover
                                +bg(black, red)
                                +scale(1.1, 1.1)
                                +transition5
                        .iconb
                            +p(.5rem 1.5rem)
                            +translateY(-50px)
            .bottom
                +columnFlex(.5rem)
                +bg(whitesmoke)
                +p(1rem)
                .text
                    +size(14px)
                    +spacing(1px, .2rem)
                .price
                    +font(18, black, 700)
                +transition5
            &:hover > .bottom
                +bg(black, white)
                +transition5
            
            &:hover > .top > .photo > .links > .icona
                +translate(0, 0)
                +opacity(1)
                +autoPointer
            &:hover > .top > .photo > .links > .iconb
                +opacity(1)
                +autoPointer
                +translate(0, 0)
            &:hover > .top > .photo > .links > .iconc
                +autoPointer
                +opacity(1)
                +translate(0, 0)
            &:hover > .top > .photo > .img2
                +opacity(1)
                +transition5
            &:hover > .top > .like > .heart
                +opacity(1)
                +transition5
                +autoPointer
                +pointer
            
            &:hover > .bottom > .price
                +color(red)
                +transition5
                    