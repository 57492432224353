@import '../../Utils/index'


.Compc
    +mc
    h2
        +style(italic)
        +centerText
        +pbl(3rem 1rem)
        +color(red)
    .parent
        +grid($columnGap: 1rem, $rowGap: 2.5rem)
        +p(1rem)
        +gtc(repeat(auto-fit, minmax(200px, 1fr)))
        .box
            +container(200px, null, black)
            +flex(null, center, flex-end)
            +relative
            +pointer
            img
                +coverImage
            .last
                +container(50px, 80%, white)
                +index(3)
                +absolute
                +pin(1rem)
                +flex(2rem, space-between, center)
                +transition5
                +bottom(-25px)
                +left(50%)
                +translateX(-50%)
                +bb(2px solid black)
                p
                    +transition5
                    +size(15px)
            &::after
                +pseudo($height: 0, $width: 0, $top: 50%, $left: 50%, $bg: $gray-300)
                +transition
            &:hover::after
                +pseudo($height: 100%, $width: 100%, $top: 0, $left: 0, $right: 0, $bottom: 0)
                +opacity(.3)
                +index(1)
            &:hover > .last > p
                +color(red)
            &:hover > .last
                +bb(2px solid red)
            
