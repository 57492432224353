=shadow($shadow)
    box-shadow: $shadow
=transparent
    background: transparent 
=opacity($opacity)
    opacity: $opacity
=none()
    display: none
=block()
    display: block
=hidden()
    overflow: hidden
=hiddenX()
    overflow-x: hidden
=hiddenY()
    overflow-y: hidden
=auto()
    overflow: auto
=autoX()
    overflow-x: auto
=autoY()
    overflow-x: auto


=pointer()
    cursor: pointer
=noPointer()
    pointer-events: none
=autoPointer()
    pointer-events: auto

=transition1($prop: all)
    transition: 100ms $prop
=transition2($prop: all)
    transition: 200ms $prop
=transition3($prop: all)
    transition: 300ms $prop
=transition4($prop: all)
    transition: 400ms $prop
=transition5($prop: all)
    transition: 500ms $prop
=transition6($prop: all)
    transition: 600ms $prop
=transition7($prop: all)
    transition: 700ms $prop
=transition8($prop: all)
    transition: 800ms $prop
=transition9($prop: all)
    transition: 900ms $prop
=transition($time: 1000ms, $prop: all)
    transition: $time $prop

=translate($x, $y)
    transform: translate($x, $y)
=translateX($value)
    transform: translateX($value)
=translateY($value)
    transform: translateY($value)

=scale($x, $y)
    transform: scale($x, $y)
=scaleX($value)
    transform: scaleX($value)
=scaleY($value)
    transform: scaleY($value)

=rotate($x, $y)
    transform: rotate($x, $y)
=rotateX($value)
    transform: rotateX($value)
=rotateY($value)
    transform: rotateY($value)

=borderBox
    box-sizing: border-box
=contentBox
    box-sizing: content-box
=index($index)
    z-index: $index