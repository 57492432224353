@import '../../Utils/index'


.Compd
    +pin(3rem)
    +mb(2rem)
    @media (max-width: 1100px) 
        +pin(0)
    h2
        +style(italic)
        +centerText
        +pbl(3rem 1rem)
        +color(red)
    .parent
        +p(2rem 2rem)
        +bg(whitesmoke)
        @media (max-width: 1000px) 
            +p(2rem 1rem)
        .container
            +grid(2rem)
            +gtc(1fr 1fr)
            @media (max-width: 1000px) 
                +gtc(1fr)
            .same
                +pointer
                +grid(1rem)
                +gtc(1fr 1fr)
                @media (max-width: 650px) 
                    +gtc(1fr)
                .a
                    +radius(.2rem)
                    +hidden
                    img
                        +containImage
                        +height(220px)
                .b
                    +p(.5rem 2rem)
                    +columnFlex(1rem, center, flex-start)
                    @media (max-width: 650px) 
                        +p(.5rem 0)
                    .stars
                        +flex(.5rem)
                    .calendar
                        +flex(.5rem)
                        .box
                            +columnFlex()
                            +radius(.2rem)
                            +hidden
                            +centerText
                            .top
                                +bg(red)
                                +font(13px, white, 800)
                                +p(.2rem)
                            .btm
                                +bg(yellow)
                                +p(.2rem)
                                +size(10px)
