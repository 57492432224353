@import '../../Utils/index'



.Navigation
    .parent
        .mobilenav
            +none
            @media (max-width: 1100px) 
                +block
        .nav
            +block
            @media (max-width: 1100px) 
                +none
        