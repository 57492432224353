@import '../../Utils/index'


.Nav
    +relative
    .parent
        +relative
        .search
            +height(90px, $gray-900)
            +grid()
            +gtc(1fr 2fr 1fr)
            .logo
                +font(27px, null, 700)
                +pin(2rem)
                +flex(null, flex-start, center)
                .name

                    .haz
                        +color(red)
                    .cui
                        +color(orange)
                        +italic
            .input
                +p(1.3rem)
                #input
                    +radius(.2rem)
                    +flex(.5rem)
                    +container(100%, 100%, $gray-100)
                    +p(.3rem)
                    input
                        +container(100%, 100%, transparent)
                        +nooutline
                        +pin(1rem)
                    button
                        +pin(1rem)
                        +radius(.2rem)
                        +size(12px)
                        +pointer
                        +nooutline
                        +bg(orange, white)
            .others
                +flex(1.5rem, flex-end, center)
                +color(white)
                .wrapper
                    +columnFlex(.3rem)
                    .title
                        +font(11px, white, 700)
                        +transition5
                .icon
                    +size(35px)
                    +transition5
                .subtitle1
                    +size(12px)
                    +flex(1rem)
                .subtitle2
                    +size(10px)

                .cart
                    +flex(.2rem, center, center)
                    +pointer
                    span
                        +font(13px, orange, 800)
                    &:hover > .icon
                        +color(orange)
                    &:hover > .wrapper > .title
                        +color(orange)

                        
                .user
                    +flex(.2rem, center, center)
                    +pointer
                    &:hover > .icon
                        +color(orange)
                    &:hover > .wrapper > .title
                        +color(orange)

        .links
            +sticky
            +width(100%)
            +pin(2rem)
            +flex(null, null, center)
            +container(50px, 100%, $gray-900)
            ul
                +noList
                +flex(.5rem, flex-start, center)
                li
                    a
                        +noDecoration
                        +pin(1rem)
                        +transition5
                        +font(14px, white)
                        &:hover
                            +color(red)